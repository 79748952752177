.action-bar {
  padding: 22px;
}

.action-button {
  padding: 5px 20px;
  border: 3px solid #be2448;
  text-align: center;
  background: white;
  border-radius: 50px;
}

.add-ops-board {
  padding: 75px 56px;
  background-color: #fbfbfb;
  width: 50%;
  max-width: 680px;
  min-width: 500px;
  min-height: calc(100vh - 80px);
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.add-ops-board .title {
  font-weight: 600;
  color: #c32e52;
}

.add-ops-board .add-btn {
  margin-top: 50px;
  padding: 16px 50px;
  direction: rtl;
  background-color: #3bce63;
  border: none;
  cursor: pointer;
}

.add-ops-board .add-btn.disabled {
  margin-top: 50px;
  padding: 16px 50px;
  direction: rtl;
  background-color: #d0edd8;
  border: none;
  cursor: not-allowed;
}

.add-ops-board .ops-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-ops-board .ops-form .error-message {
  max-width: 200px;
}

.add-ops-board .input-group {
  margin-top: 30px;
}

.expand {
  padding: 20px;
  background-color: #fcf5f2;
}

.toggle-btn {
  margin-left: 20px;
  padding: 8px 20px;
  direction: rtl;
  border: none;
  cursor: pointer;
}

.toggle-btn.active {
  background-color: #3bce63;
}

.toggle-btn.disable {
  background-color: #c32e52;
  color: white;
}

.edit-btn {
  margin-left: 20px;
  padding: 8px 20px;
  background-color: #555555;
  color: white;
  direction: rtl;
  border: none;
  cursor: pointer;
}

.text-red.selected {
  color: white;
}

.text-green.selected {
  color: white;
}
