@import url(//fonts.googleapis.com/earlyaccess/notokufiarabic.css);
* {
  font-family: 'Noto Kufi Arabic', sans-serif;
  font-size: 12px;
}
html,
body {
  margin: 0;
}
.sc-lmoMRL {
  direction: rtl;
}
input {
  direction: rtl;
}

#root {
  font-size: 12px;
}

#search {
  position: absolute;
  padding: 10px;
  padding-right: 20px;
  border: 0px;
  border-top: 1px solid #ddd;
  background: #f3f3f3;
  color: #000;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-family: 'Noto Kufi Arabic', sans-serif;
  left: 0;
  right: 0;
}
.rdt_TableRow {
  overflow-x: hidden;
}

.exportBtn {
  position: absolute;
  left: 30px;
  background: #c32e52;
  height: 35px;
  border: 0;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  border-radius: 100px;
  align-self: flex-start;
  float: left;
}
.wait {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 100px 0;
  color: #ccc;
  direction: rtl;
}
.search-order {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  box-sizing: border-box;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #ffffff;
  min-height: 52px;
}
.search-order-new {
  justify-content: flex-start;
}
.search-input {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.btn-search {
  padding: 5px 10px;
  margin-left: 10px;
  color: rgb(52, 43, 64);
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}
.flex-end {
  justify-content: flex-end;
}
.mb-10 {
  margin-bottom: 10px;
}
.checkbox-order {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.checkbox-order input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: #eee;
  border-radius: 3px;
}
.checkbox-order:hover .checkmark {
  background: #ccc;
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox-order .checkmark:after {
  top: 50%;
  left: 50%;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}
.checkbox-order input:checked ~ .checkmark {
  background: #2196f3;
}
.checkbox-order input:checked ~ .checkmark:after {
  display: block;
}
.label-search {
  margin: 0;
}
.filter {
  margin: 5px;
}

a.refresh-button {
  color: #000;
  text-decoration: none;
}
.mr-2 {
  margin-right: 0.5rem;
}

h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}

.wrap-loading-header{
  opacity: 0.5;
}
.wrap-icon-loading-order {
  z-index: 21;

}
