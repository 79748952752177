.bg-red {
  background-color: rgba(244, 91, 105, 1);
}

.login {
  overflow: hidden;
  background-color: white;
  padding: 40px 30px 30px 30px;
  border-radius: 10px;
  margin-top: 100px;
  position: absolute;
  width: 400px;
  box-shadow: 5px 10px 10px rgba(2, 128, 144, 0.2);
}

.login::before,
.login::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  border-top-left-radius: 40%;
  border-top-right-radius: 45%;
  border-bottom-left-radius: 35%;
  border-bottom-right-radius: 40%;
  z-index: -1;
}

.login::before {
  left: 40%;
  bottom: -130%;
  background-color: rgba(69, 105, 144, 0.15);
}

.login::after {
  left: 35%;
  bottom: -125%;
  background-color: rgba(2, 128, 144, 0.2);
}

.login > input {
  font-family: 'Asap', sans-serif;
  display: block;
  border-radius: 5px;
  font-size: 16px;
  background: white;
  width: 100%;
  border: 1px solid;
  padding: 10px 10px;
  margin: 15px -10px;
}

.login > button {
  font-family: 'Asap', sans-serif;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  width: 80px;
  border: 0;
  padding: 10px 0;
  margin-top: 10px;
  margin-left: -5px;
  border-radius: 5px;
  background-color: rgba(244, 91, 105, 1);
}

.login > button:hover {
  background-color: rgba(242, 67, 83, 1);
}
