.action-list {
  margin: 20px 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.tab-btn {
  cursor: pointer;
  border: 2px solid #d1204c;
  background: white;
  color: #d1204c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all ease 0.3s;
}

.tab-btn.active-tab {
  background: #d1204c;
  color: white;
}

.tab-btn:hover {
  background: #e84d71;
  color: white;
  transition: all ease 0.3s;
}

.tab-btn:active {
  background: #be2448;
  color: white;
}
