.pin {
  border-radius: 50% 50% 50% 0;
  /* position: absolute; */
  left: 50%;
  top: 50%;
  cursor: pointer;
}

.pin img {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 0;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.count-att {
  font-weight: bold;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.driver-icon-location {
  z-index: 99;
}

.w-50 {
  width: 100%;
}

/* .driver-no-order.pulse::after {
  box-shadow: 0 0 1px 2px #3ed57c;
}
.driver-has-order.pulse::after {
  box-shadow: 0 0 1px 2px #51bfff;
}

.order .new.pulse::after {
  box-shadow: 0 0 1px 2px #3ed57c;
}
.order .assigned.pulse::after {
  box-shadow: 0 0 1px 2px #51bfff;
}

.restaurant .pulse::after {
  box-shadow: 0 0 1px 2px #d6d4d4;
} */

.restaurant .pin {
  color: #d6d4d4;
}

.pin svg {
  width: 20px;
  height: 20px;
}

.driver .driver-has-order.pin {
  color: #51bfff;
}
.driver .driver-no-order.pin {
  color: #3ed57c;
}
.order .assigned.pin {
  color: #51bfff;
}
.order .new.pin {
  color: #3ed57c;
}

/* .pulse {
  background: #d6d4d4;
  border-radius: 50%;
  border: 1px solid;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: -2;
} */

/* .driver .driver-has-order.pulse {
  background: #51bfff;
  border-color: #51bfff;
}
.driver .driver-no-order.pulse {
  background: #3ed57c;
  border-color: #3ed57c;
} */

/* .pulse:after {
  content: '';
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -10px 0 0 -10px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  animation-delay: 1.1s;
  box-shadow: 0 0 1px 2px #d6d4d4;
} */

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(0.8, 0.8);
    opacity: 0;
  }
}
.marker {
  position: relative;
}

.marker.marker-active img {
  transform: scale(1.2);
}

.marker.marker-active svg {
  transform: scale(1.2);
}

.driver-tooltip {
  min-width: 210px;
  max-width: 300px;
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  border: 1px solid #fff;
  top: -5px;
  left: 35px;
  opacity: 0;
  transition: all 0.5ms;
  z-index: 1000;
}

.driver-tooltip::before {
  content: '';
  position: absolute;
  height: 12px;
  width: 12px;
  background: #fff;
  transform: rotate(45deg);
  left: -6px;
  top: calc(50% - 6px);
}
.driver-tooltip.show {
  opacity: 1;
}
.tracking-driver {
  height: calc(100vh - 80px);
  display: flex;
}
.maps {
  height: 100%;
  width: 60%;
  position: relative;
}

.cards {
  width: 20%;
  background-color: #f7f8fc;
  overflow: hidden;
}

.cards nav {
  overflow: auto;
  height: 100%;
}

/* .cards nav::-webkit-scrollbar {
  display: none;
} */

.card-drivers.cards nav {
  overflow: auto;
  height: calc(100% - 230px);
}

.cards nav ul {
  list-style: none;
  padding: 0 10px;
  margin: 0;
}

.cards nav li {
  float: left;
  width: 100%;
  margin-top: 10px;
  background-color: #ffffff;
  padding: 0;
  border-radius: 5px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgb(82 63 105 / 10%);
}

.pt-2 {
  padding-top: 4px;
}
.pl-2 {
  padding-left: 4px;
}

.cards nav li .content {
  padding: 5px;
}

.cards nav li .content h1 {
  font-size: 13px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.4px;
  margin: 0;
  color: #d14836;
  display: flex;
}

.cards nav li .content span {
  font-size: 12px;
  color: #b3b3b3;
  padding: 2px;
}

.driver-info {
  width: 100%;
  padding: 0 10px;
}

.driver-info .inprogress,
.driver-info .already {
  float: right;
}

.driver-location .icon-location {
  padding-right: 4px;
}
/* custom scrollbar */
.cards nav::-webkit-scrollbar {
  width: 20px;
}

.cards nav::-webkit-scrollbar-track {
  background-color: transparent;
}

.cards nav::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cards nav::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.map-search {
  display: block;
  position: relative;
  margin: 10px;
}

.map-search .input-search {
  height: 40px;
  border: 1px solid #fff;
  border-radius: 9999px;
  font-size: 14px;
  background-color: #fff;
  padding: 0 20px;
  width: 100%;
}

.map-search .input-search:focus {
  outline: none;
}

.map-search .button-search {
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  margin-left: 10px;
}
.map-search .search-icon {
  height: 16px;
  width: 16px;
}
.pr-2 {
  padding-right: 10px;
}

.card {
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #5a6c7a;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  box-shadow: 0 0.1rem 0.25rem #aac1c7;
  margin-top: 10px;
}
.card__count-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.card__count-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card__count-text--big {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 300;
  margin-right: 2px;
  display: flex;
  justify-content: center;
}

.button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.py-1 {
  padding: 4px 0;
}

.assign-button {
  background: #be2448;
  color: #fff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-order {
  overflow: hidden;
  height: 100vh;
  padding-bottom: 300px;
}

.my-5 {
  margin-bottom: 5px;
  margin-bottom: 5px;
}

.card-order .driverListHolder .driversList {
  direction: rtl;
  /* float: right; */
}

.card-order .driverListHolder {
  position: fixed;
  display: flex;
  justify-content: flex-end;
}

.card-order .driverListHolder .DriversListContainer {
  padding: 30px;
}

.card-order .driverListHolder .DriversListContainer .driversList,
.card-order .driverListHolder .DriversListContainer input {
  width: 100%;
}

.cards .content {
  padding: 0 10px;
}

.driver-dashboard {
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.order-info {
  direction: rtl;
  width: 100%;
}

.order-customer {
  direction: rtl;
  position: relative;
}
.order-information {
  margin-top: 4px;
  direction: rtl;
  display: flex;
  position: relative;
}
.info-customer {
  direction: rtl;
  display: flex;
  flex-direction: column;
}
.cards nav li .content .order-information h1 {
  font-size: 14px;
  font-weight: bold;
  margin-left: 10px;
}
.cards nav li .content .order-information span,
.cards nav li .content .info-customer span {
  color: #000;
  display: flex;
}

.cards nav li .content .info-customer h3 {
  color: #000;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  margin: 5px 0 0 0;
}

.cards nav li .content span.inprogress {
  padding: 3px 5px;
  background-color: #d59563;
  margin-right: 4px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}
.cards nav li .content span.new {
  padding: 3px 5px;
  background-color: #41e25c;
  margin-right: 4px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0px;
}
.card-drivers.cards nav li .content span.label {
  padding: 6px 7px;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
}
.card-drivers.cards nav li .content h1 {
  font-size: 13px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0.4px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #d14836;
  display: flex;
  direction: rtl;
}
.cards nav li .content span.already {
  background-color: #41e25c;
}

.cards nav li .content span.offline {
  background-color: #d14836;
  color: #fff;
}

.card-drivers.cards nav li .content span.inprogress {
  background-color: #51bfff;
}

.cards nav li .content span.inprogress,
.cards nav li .content span.new,
.cards nav li .content span.already {
  color: #fff;
}
.driver-location {
  margin-top: 5px;
}
.card-orders .tab-header {
  justify-content: center;
}

.card-orders {
  position: relative;
}

.assignModal.show {
  display: block;
}

.assignModal {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f7f8fc;
  z-index: 100;
  display: none;
}

.assignModal .content.driver-info {
  height: calc(100% - 100px);
}

.button-back {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}

.header-assign {
  width: 100%;
  padding: 5px;
  background-color: #fff;
  height: 50px;
  position: relative;
}

.items-center {
  align-items: center;
}
.cards nav li .content .count-att {
  margin: 5px 0;
}

.cards nav li .content .count-att span {
  font-size: 12px;
  color: #b3b3b3;
}

.cards nav li .content .count-att .item-count {
  padding: 2px 8px;
}

.cards nav li .content .count-att .item-count span {
  padding: 0 2px;
}

.mt-2 {
  margin-top: 10px;
}

.justify-end {
  justify-content: flex-end;
}

.cards nav li .content {
  padding: 15px 10px;
}

.to-invoice {
  margin: 0 5px;
  background: #b3b3b3;
  color: #fff;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.w-6 {
  width: 24px;
}
.h-6 {
  height: 24px;
}
/*
.tracking-driver.map-tracking .maps .gm-style img {
  width: 100% !important;
  height: 100% !important;
} */

.btnWhatsapp{
width: 32px;
height: 32px;
  
}

.phoneNumberDriver{
  background-color: #ccc;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
  border-radius: 4px;
}
.notifyDriver{
  background-color: #e0eff7;
  color: grey;
}

@media (min-width: 1400px) {
  .wrapBtnWhatsapp {
    padding: 6px 18px 0px 18px;
    display: flex;
  }
 
}

@media (max-width: 1400px) {
.btnWhatsappLg {
  padding:0px 18px;
  margin-top: 12px;
  
  }
}

.inputNoti{
      border: 1;
          padding: 5;
          border-radius: 5;
          padding-left: 10;
          padding-right: 10;
          margin-right: 40
}
.loadingContainer{
    width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        z-index: 99;
        background-color: #e5e3df;
        display: flex;
}
.iconLoading{
  width: 36px; height: 36px
}

.driverCardMessages{
  border: 0;
  background-color: #34A5FA;
  padding: 8px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  cursor: 'pointer';
}
.driverInputMessages{
  border: 1px solid #e7e7ef;
  padding: 5px;
  border-radius: 5px;
  padding-right: 10px;
  margin-right: 20px;
  width: '100%',
}
.driverSendNoti{
  border: 0;
  background-color: #31C961;
  padding: 8px 20px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  cursor: 'pointer',
}
