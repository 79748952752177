@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
.quarter-spinner {
  width: 30px;
  height: 30px;
  border: 2px solid #fb5b53;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1000;
}

.new_driver_container {
  padding: 30px;
  direction: rtl;
  background: #fbfbfb;
  width: 460px;
  float: right;
}

.new_driver_container h3 {
  color: #c32e52;
}

.row {
  padding-top: 10px;
  padding-bottom: 10px;
}

.row label {
  width: 200px;
  font-size: 14px;
  font-weight: 100;
}

.row input[type='text'],
.row input[type='password'],
.row input[type='number'] {
  width: 200px;
  height: 35px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.row input[type='date'] {
  width: 200px;
  height: 35px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.input60 {
  width: 60px !important;
  border-left: 0 !important;
  text-align: center;
}

.input80 {
  width: 80px !important;
  text-align: center;
}

.ltr {
  direction: ltr;
}

.rtl {
  direction: rtl;
}

.center {
  text-align: center;
}

.registerBtn {
  background: #3bce63;
  border: 0;
  width: 80px;
  /* height: 35px; */
  color: #1c6f32;
  /* margin-right: 300px; */
}

.publicMessageHolder {
  display: flex;
  justify-content: flex-end;
  padding: 15px;
}

.publicMessageBtn {
  background: #3bce63;
  border: 0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  height: 35px;
  color: #1c6f32;
}

.displayHolder {
  display: flex;
  align-items: center;
}

.displayHolder span {
  margin-left: 20px;
}

.toggleLiveHolder {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  /* width: 190px; */
  width: 190px;
  height: 38px;
  align-items: center;
  border-radius: 100px;
  padding-left: 5px;
  padding-right: 5px;
}
.toggleLiveHolderOrder {
  width: 270px;
}

.live {
  display: flex;
  flex: 1;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}

.offline {
  display: flex;
  flex: 1;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  cursor: pointer;
}

.selectedDisplay {
  background-color: #fff;
  border: 2px solid #c32e52;
}

.toggleLiveHolder a {
  text-decoration: none;
  color: #000;
}

.attachmentImage {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  border-radius: 10px;
}

.upload-select,
.upload-list-picture-card-container {
  width: 104px;
  height: 104px;
  margin: 0 4px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  transition: border-color 0.3s;
  display: inline-block;
}

.upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
  min-width: 104px;
}

.upload-select-picture-card > .upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.upload-list-item {
  position: relative;
  height: 100%;
  padding: 8px;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
}

.upload-list-picture-card-container .upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}

.upload-list-picture-card-container .upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden;
}

.upload-list-item-actions .remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  color: tomato;
}

.upload-list-item-actions .remove-button svg {
  font-size: 15px;
}

.rdt_TableHeader > div {
  width: 100%;
}

.refresh-button {
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px 20px;
  border-radius: 50px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 1s;
  background-color: #fff;
  border: 2px solid #c32e52;
}

.refresh-button:disabled {
  cursor: default;
  transition: 1s;
  background-color: #f2f2f2;
  color: #818181;
  border: 2px solid #ddd;
}

.create-fake-order-button {
  margin-left: 0px;
  margin-right: 0px;
}

#table {
  min-width: 500px;
}

#table td {
  padding: 12px 10px;
}

#table tr:nth-child(even) {
  background-color: #f2f2f2;
}

#table tr:hover {
  background-color: #ddd;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.upload-list-item-info {
  height: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px !important;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.prettyprint {
  direction: ltr;
  line-height: 25px;
  letter-spacing: 0.25px;
}

.justify-end {
  justify-content: flex-end;
}

.tab-header {
  /* border-bottom: 1px solid #ccc; */
  padding: 20px;
  justify-content: end;
}

.tab-button {
  font-size: 14px;
  line-height: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-bottom-width: 2px;
  white-space: nowrap;
  align-items: center;
  margin-right: 2rem;
  font-weight: 500;
  border: 0;
  background-color: transparent;
}
.tab-button.tab-active {
  border-bottom: 2px solid #b3b3b3;
}
.tab-body {
  padding: 16px;
}

.order-tab {
  background-color: #fff;
}

.setting {
  padding: 30px;
  direction: rtl;
  background: #fbfbfb;
  width: 460px;
  float: right;
  width: 100%;
}
.saveButton {
  background: #3bce63;
  border: 0;
  width: 100px;
  height: 35px;
  color: #1c6f32;
}
.direction-r {
  direction: rtl;
}
.collapsible-content-edonec {
  overflow: hidden;
  transition: height 0.2s ease-in-out;
}
.title-text-edonec {
  display: block;
  font-size: 1em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.collapsible-header-edonec {
  display: flex;
  margin: 15px 15px 0;
  justify-content: space-between;
  padding: 2px 0;
  transition: 0.3s;
  border-bottom: 1px solid #dee2e6 !important;
}
.collapsible-content-padding-edonec {
  padding: 20px 20px 20px 20px;
}
.rotate-center-edonec {
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.rotate-center-edonec.down {
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.rotate-center-edonec.up {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.collapsible-icon-button-edonec {
  cursor: pointer;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.filter-input {
  height: 35px;
  padding: 6px;
  border: 1px solid hsl(0deg 0% 80%);
  border-radius: 4px;
  width: 240px;
}

.filter-select {
  min-width: 240px !important;
}

.filter-input:focus-visible {
  border: 1px solid hsl(0deg 0% 80%);
  outline: none;
}
.react-datetimerange-picker__wrapper {
  height: 38px;
  border: 1px solid hsl(0deg 0% 80%);
  border-radius: 4px;
}

.react-date-picker__wrapper {
  height: 38px;
  border: 1px solid hsl(0deg 0% 80%);
  border-radius: 4px;
}

.direction-r {
  direction: rtl;
}
.eHxVgR svg {
  right: -8px !important;
}

.row textarea {
  resize: vertical;
  width: 200px;
  height: 35px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.row .select-box {
  width: 200px;
  height: 35px;
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.input-search-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-search-wrapper .input-search-title {
  margin: 0;
  font-weight: 600;
}

.input-search-wrapper .input-search-input-wrapper {
  border: 1px solid #cccccc;
  width: 240px;
  min-width: 240px;
  min-height: 38px;
  height: 38px;
  font-size: 12px;
  border-radius: 4px;
}

.input-search-wrapper .input-search-input-wrapper > .input-search {
  padding: 8px 40px 8px 12px;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.input-search-wrapper .input-wrapper {
  border: 1px solid #cccccc;
  min-width: 240px;
  min-height: 38px;
  height: 38px;
  font-size: 12px;
  border-radius: 4px;
}

.input-search-wrapper .input-wrapper > .input-search {
  padding: 8px 40px 8px 12px;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.input-select-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.input-select-wrapper .input-select-title {
  margin: 0;
  font-weight: 600;
}

.input-select-wrapper .input-select-input-wrapper {
  border: 1px solid #cccccc;
  min-width: 240px;
  min-height: 38px;
  height: 38px;
  font-size: 12px;
  border-radius: 4px;
  min-height: inherit;
  cursor: pointer;
}

.input-select-wrapper .input-select-input-wrapper .input-select {
  padding: 8px 40px 8px 12px;
  border: none;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  min-height: inherit;
  display: flex;
}

.input-select-wrapper .input-select-input-wrapper .input-select .input-select-current {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.input-select-wrapper .input-select-input-wrapper .input-select .input-select-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #6f6f6f;
}

.input-select-wrapper .input-select-input-wrapper .input-select-options {
  border: 1px solid #00000006;
  position: absolute;
  top: 55px;
  left: -1px;
  visibility: hidden;
  transition: all ease 0.2s;
  opacity: 0;
  transform: translateY(-10px);
  width: 100%;
  min-width: 220px;
  max-width: 250px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 16px 25px 6px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 16px 25px 6px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 350px;
  z-index: 10;
}

.input-select-wrapper .input-select-input-wrapper .input-select-options.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  display: flex;
  flex-direction: column;
}

.input-select-wrapper .input-select-input-wrapper .input-select-options .option {
  background: #ffffff;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;
}

.input-select-wrapper .input-select-input-wrapper .input-select-options .option:hover {
  background: #be2448;
  color: white;
}

.map-marker-hint--title {
  font-weight: bold;
}

.select-disable-grey {
  background-color: #f0f0f0;
}

.react_select__control--is-disabled {
  cursor: not-allowed !important;
  pointer-events: all;
}

.react_select__multi-value--is-disabled {
  pointer-events: none;
}

.balance-value {
  direction: ltr;
}

.input-range__track--active,
.input-range__slider {
  background: #4990e2 !important;
  border-color: #4990e2 !important;
}

._line-clamp_2 {
  overflow: hidden;
  line-height: 1.5rem;
  height: 3rem;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Change this line if you want. In this case it trimmed the text to 3 lines. */
  overflow: hidden;
}
