.month-year-picker {
  border-width: 1px solid red;
}

.month-year-picker .caption {
  padding-left: 10px;
  margin-bottom: 20px;
  display: block;
}

.month-year-picker .year-picker {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.month-year-picker .year-picker span {
  font-size: 25px;
  color: #5186ed;
  text-align: center;
  width: 100%;
  display: block;
}

.month-year-picker .year-picker .controls {
  position: absolute;
  right: 0;
  width: 80px;
  top: 0;
  height: 100%;
  display: flex;
}

.month-year-picker .year-picker .controls .fa {
  margin: auto;
  cursor: pointer;
  font-size: 25px;
}

.month-year-picker .year-picker .controls .fa.disabled {
  cursor: default;
  opacity: 0.3;
}

.month-year-picker .month-picker {
  position: relative;
  width: 100%;
}

.month-year-picker .month-picker>div {
  position: relative;
  width: 100%;
  margin: auto;
  display: block;
}

.month-year-picker .month-picker>div>div {
  background-color: #ddd;
  color: cornflowerblue;
  height: 30px;
  margin: 5px;
  width: 30px;
  display: inline-flex;
  cursor: pointer;
}

.month-year-picker .month-picker>div>div.selected {
  background-color: #5186ed;
  color: #FFFFFF;
}

.month-year-picker .month-picker>div>div span {
  margin: auto;
  font-size: 12px;
  font-style: normal;
}

.filter-by{
  width: 160px;
}
